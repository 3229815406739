/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
  Navbar, Image, Nav, Container, NavDropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  getCurrentUser, isLoggedIn, isAdmin,
} from '../services/auth';
import './MenubarTop.css';

const MenubarTop = ({ selectedYear, pastYears }) => {
  const [userDropdown, setShowUserDropdown] = useState(false);
  const [adminDropdown, setShowAdminDropdown] = useState(false);
  const [pastYearsDropdown, setShowPastYearsDropdown] = useState(false);

  const [user, setUser] = useState('');
  const [isLoggedInState, setIsLoggedIn] = useState(false);
  const [isAdminStatus, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsLoggedIn(isLoggedIn());
    if (isLoggedInState) {
      setUser(getCurrentUser().username);
      setIsAdmin(isAdmin());
    }
  }, [isLoggedInState]);

  const showUserDropdown = (e) => {
    setShowUserDropdown(!userDropdown);
  };
  const hideUserDropdown = (e) => {
    setShowUserDropdown(false);
  };

  const showAdminDropdown = (e) => {
    setShowAdminDropdown(!adminDropdown);
  };
  const hideAdminDropdown = (e) => {
    setShowAdminDropdown(false);
  };

  const showPastYearsDropdown = (e) => {
    setShowPastYearsDropdown(!pastYearsDropdown);
  };
  const hidePastYearsDropdown = (e) => {
    setShowPastYearsDropdown(false);
  };

  const handleStravaConnect = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      window.open(`http://localhost:${process.env.REACT_APP_API_PORT}/api/strava/connect`, '_self');
    } else {
      window.open('/api/strava/connect', '_self');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.open('/home', '_self');
  };

  const storageChanged = () => {
    setIsLoggedIn(isLoggedIn);
    if (isLoggedInState) {
      setUser(getCurrentUser().username);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', () => storageChanged);
  });

  return (
    <Navbar variant="dark" bg="dark" expand="md">
      <Container fluid>
        <Navbar.Brand href="https://sokoltrencin.sk">
          <img
            src="/sokol-logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="sokol logo"
          />
          <Navbar.Text style={{ color: 'white', paddingLeft: '.5em' }}>
            {selectedYear ? format(new Date(selectedYear.startDate), 'd.M.') : ''}
            &thinsp;&ndash;&thinsp;
            {selectedYear ? format(new Date(selectedYear.endDate), 'd.M.') : ''}
            {selectedYear ? selectedYear.year : 0}
          </Navbar.Text>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="justify-content-center align-items-center flex-grow-1">
            <Nav.Link href="/">Domov</Nav.Link>

            {pastYears.length > 0
              && (
              <NavDropdown
                title="Minulé ročníky"
                id="pastYearsDropdown"
                show={pastYearsDropdown}
                onMouseEnter={showPastYearsDropdown}
                onMouseLeave={hidePastYearsDropdown}
                menuVariant="dark"
              >
                {pastYears.map((year, i) => (
                  <div>
                    <NavDropdown.Item href={`/${year}`}>{year}</NavDropdown.Item>
                    {i !== pastYears.length - 1 && <NavDropdown.Divider />}
                  </div>
                ))}
              </NavDropdown>
              )}

            {isAdminStatus && (
              <NavDropdown
                title="Administrácia"
                id="adminDropdown"
                show={adminDropdown}
                onMouseEnter={showAdminDropdown}
                onMouseLeave={hideAdminDropdown}
                menuVariant="dark"
              >
                <NavDropdown.Item href="/admin/users">Užívatelia</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/activities">Aktivity</NavDropdown.Item>
              </NavDropdown>
            )}

            <Nav className="flex-fill" />

            {isLoggedInState ? (
              <NavDropdown
                title={user}
                id="userDropdown"
                show={userDropdown}
                onMouseEnter={showUserDropdown}
                onMouseLeave={hideUserDropdown}
                menuVariant="dark"
              >
                <NavDropdown.Item href="/profile">Profil</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>Odhlásiť sa</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link onClick={handleStravaConnect} className="p-0">
                <Image
                  src="/btn_strava_connectwith_orange.png"
                  alt="Connect with Strava"
                  width="193px"
                />
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MenubarTop.propTypes = {
  selectedYear: PropTypes.shape({
    year: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  pastYears: PropTypes.arrayOf(PropTypes.number),
};

MenubarTop.defaultProps = {
  pastYears: [],
};

export default MenubarTop;
