import axios from 'axios';
import authHeader from './auth-header';
import { API } from './api';

const resetStravaActivities = (year) => axios
  .get(`${API}/admin/resetStravaActivities?year=${year}`, { headers: authHeader() });

export {
  // eslint-disable-next-line import/prefer-default-export
  resetStravaActivities,
};
