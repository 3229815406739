import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AllUsersStatsTableCell from './AllUsersStatsTableCell';
import './AllUsersStatsTableMobile.css';

const AllUsersStatsTableMobile = ({ data, showAwards }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <Accordion alwaysOpen defaultActiveKey={['0']}>
      <Accordion.Item eventKey="0">
        <Accordion.Header><div style={{ fontWeight: 'bold' }}>Vzdialenosť</div></Accordion.Header>
        <Accordion.Body style={{ padding: '0rem 0rem' }}>
          <AllUsersStatsTableCell data={tableData} field="distance" showAwards={showAwards} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><div style={{ fontWeight: 'bold' }}>Prevýšenie</div></Accordion.Header>
        <Accordion.Body style={{ padding: '0rem 0rem' }}>
          <AllUsersStatsTableCell data={tableData} field="elevation" showAwards={showAwards} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><div style={{ fontWeight: 'bold' }}>Počet jázd</div></Accordion.Header>
        <Accordion.Body style={{ padding: '0rem 0rem' }}>
          <AllUsersStatsTableCell data={tableData} field="activitiesCnt" showAwards={showAwards} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <div style={{ fontWeight: 'bold' }}>
            Počet jázd
            {' '}
            {'>'}
            {' '}
            30 km
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: '0rem 0rem' }}>
          <AllUsersStatsTableCell data={tableData} field="longActivitiesCnt" showAwards={showAwards} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <div style={{ fontWeight: 'bold' }}>Najdlhšia aktivita</div>
        </Accordion.Header>
        <Accordion.Body style={{ padding: '0rem 0rem' }}>
          <AllUsersStatsTableCell data={tableData} field="longest" showAwards={showAwards} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

AllUsersStatsTableMobile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  showAwards: PropTypes.bool,
};

AllUsersStatsTableMobile.defaultProps = {
  showAwards: false,
};

export default AllUsersStatsTableMobile;
