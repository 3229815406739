import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fa1, fa2, fa3, faCircle,
} from '@fortawesome/free-solid-svg-icons';
import './Award.css';

const Award = ({ pos }) => {
  const icon = () => {
    switch (pos) {
      case 1:
        return fa1;
      case 2:
        return fa2;
      case 3:
      default:
        return fa3;
    }
  };

  const cls = () => {
    switch (pos) {
      case 1:
        return 'first';
      case 2:
        return 'second';
      case 3:
        return 'third';
      default:
        return '';
    }
  };

  const title = () => {
    switch (pos) {
      case 1:
        return 'Víťaz kategórie';
      case 2:
        return '2. miesto v kategórii';
      case 3:
        return '3. miesto v kategórii';
      default:
        return '';
    }
  };

  return (
    <span className="fa-stack award" title={title()}>
      <FontAwesomeIcon icon={faCircle} className={`fa-stack-1x fa-xl ${cls()}`} />
      <FontAwesomeIcon icon={icon()} className="fa-stack-1x fa-inverse" />
    </span>
  );
};

Award.propTypes = {
  pos: PropTypes.number.isRequired,
};

export default Award;
