import axios from 'axios';
import { API } from './api';

const getAllUsersStats = (year) => axios.get(`${API}/years/${year}/users/stats`);

const getAllUsersStatsNoEbike = (year) => axios.get(`${API}/years/${year}/users/stats?ebike=false`);

const getOverallStats = (year) => axios.get(`${API}/years/${year}/users/stats-overall`);

export {
  getAllUsersStats,
  getAllUsersStatsNoEbike,
  getOverallStats,
};
