import React from 'react';
import { Container } from 'react-bootstrap';

const NotFound = () => (
  <Container className="mt-3">
    <h2>Požadovaná stránka sa nenašla</h2>
  </Container>
);

export default NotFound;
