/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import { stravaLogin, isLoggedIn } from '../services/auth';

const StravaOAuthCallback = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    stravaLogin(code)
      .then(
        () => {
          if (isLoggedIn()) {
            window.open('/profile', '_self');
          } else {
            window.open('/home', '_self');
          }
        },
        () => {
          window.open('/home', '_self');
        },
      );
  });

  return (
    <Container className="mt-3">
      <Spinner animation="border" />
    </Container>
  );
};

export default StravaOAuthCallback;
