import { React, useEffect, useState } from 'react';
import {
  Spinner, Form, Container, Row, Col, Dropdown, Stack, Image,
} from 'react-bootstrap';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import PropTypes from 'prop-types';

import { getAllUsersStats, getAllUsersStatsNoEbike } from '../services/stats';
import AllUsersStatsTable from './AllUsersStatsTable';
import AllUsersStatsTableMobile from './AllUsersStatsTableMobile';
import { useViewport } from '../helpers/custom-hooks';

const AllUsersStats = ({ year, showAwards }) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [maleData, setMaleData] = useState([]);
  const [femaleData, setFemaleData] = useState([]);
  const { width } = useViewport();
  const breakpoint = 1060;
  const [eBikeChecked, setEBikeChecked] = useState(true);
  const [sidebarHidden, setSidebarHidden] = useState(true);

  useEffect(() => {
    if (year) fetchStats(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const fetchStats = (noEBike) => {
    let fetchingFunction = getAllUsersStats;
    if (noEBike) {
      fetchingFunction = getAllUsersStatsNoEbike;
    }
    clearData();
    fetchingFunction(year).then(
      (response) => {
        response.data.forEach((athlete) => {
          if (athlete.sex === 'M') setMaleData((oldData) => [...oldData, athlete]);
          else setFemaleData((oldData) => [...oldData, athlete]);
        });
        setFetchedData(response);
      },
      (error) => {
        setFetchedData(
          (error.response
              && error.response.data
              && error.response.data.message)
            || error.message
            || error.toString(),
        );
      },
    );
  };

  const handleEBike = () => {
    setEBikeChecked(!eBikeChecked);
    fetchStats(eBikeChecked);
  };

  const clearData = () => {
    setFetchedData([]);
    setMaleData([]);
    setFemaleData([]);
  };

  return (
    <div>
      {(year === 0 || !fetchedData.data) && process.env.NODE_ENV !== 'test' ? (
        <Container className="mt-3">
          <Spinner animation="border" />
        </Container>
      ) : (
        <div>
          {(width < breakpoint) ? (
            <Container className="mt-3">
              <div style={{ marginBottom: '50px' }}>
                <Stack direction="horizontal">
                  <Dropdown className="d-inline mx-2 ms-auto">
                    <Dropdown.Toggle variant="success">
                      Nastavenia
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleEBike}>
                        <Stack direction="horizontal" gap={3}>
                          <div>E-Bike</div>
                          <div className="ms-auto">
                            <Form>
                              <Form.Check
                                reverse
                                type="switch"
                                checked={eBikeChecked}
                              />
                            </Form>
                          </div>
                        </Stack>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
                <h2 style={{ marginTop: '25px' }}>Ženy</h2>
                <AllUsersStatsTableMobile data={femaleData} showAwards={showAwards} />
                <h2 style={{ marginTop: '50px' }}>Muži</h2>
                <AllUsersStatsTableMobile data={maleData} showAwards={showAwards} />
                <Image
                  src="/api_logo_pwrdBy_strava_horiz_light.png"
                  style={{ width: '200px', marginTop: '50px' }}
                />
              </div>
            </Container>
          ) : (
            <div>
              <Container fluid className="p-0 vh-100 d-flex flex-column">
                <Row className="h-100">
                  <Col md="auto">
                    <CDBSidebar
                      toggled={sidebarHidden}
                      onMouseEnter={() => setSidebarHidden(false)}
                      onMouseLeave={() => setSidebarHidden(true)}
                      backgroundColor="#333"
                    >
                      <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large" />}>
                        Nastavenia
                      </CDBSidebarHeader>

                      <CDBSidebarContent className="sidebar-content">
                        <CDBSidebarMenu>
                          <CDBSidebarMenuItem icon="flash">
                            <Row>
                              <Col>
                                E-Bike
                              </Col>
                              <Col>
                                <Form>
                                  {process.env.NODE_ENV !== 'test' ? (
                                    <Form.Check
                                      reverse
                                      type="switch"
                                      checked={eBikeChecked}
                                      onChange={handleEBike}
                                    />
                                  ) : (
                                    <Form.Check
                                      type="switch"
                                      checked={eBikeChecked}
                                      onChange={handleEBike}
                                    />
                                  )}
                                </Form>
                              </Col>
                            </Row>
                          </CDBSidebarMenuItem>
                        </CDBSidebarMenu>
                      </CDBSidebarContent>
                    </CDBSidebar>
                  </Col>
                  <Col className="mt-3">
                    <Container fluid className="noPadding">
                      <div style={{ marginLeft: '4%', marginRight: '4%' }}>
                        <h2>Ženy</h2>
                        <AllUsersStatsTable data={femaleData} showAwards={showAwards} />
                        <h2 style={{ marginTop: '50px' }}>Muži</h2>
                        <AllUsersStatsTable data={maleData} showAwards={showAwards} />
                      </div>
                      <div style={{ marginLeft: '4%', marginRight: '4%' }}>
                        <Image
                          src="/api_logo_pwrdBy_strava_horiz_light.png"
                          style={{
                            width: '200px', marginTop: '20px', paddingBottom: '20px', float: 'right',
                          }}
                        />
                      </div>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AllUsersStats.propTypes = {
  year: PropTypes.number.isRequired,
  showAwards: PropTypes.bool,
};

AllUsersStats.defaultProps = {
  showAwards: false,
};

export default AllUsersStats;
