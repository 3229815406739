import axios from 'axios';
import authHeader from './auth-header';
import { API } from './api';

const stravaLogin = (code) => axios
  .post(`${API}/strava/oauthCallback`, { code })
  .then((res) => {
    if (res.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(res.data));
    }
  });

const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

const isLoggedIn = () => {
  if (localStorage.getItem('user')) {
    if (localStorage.getItem('user') !== 'undefined') {
      return true;
    }
    return false;
  }
  return false;
};

const logout = () => {
  localStorage.removeItem('user');
};

const requestAdminAccess = () => axios
  .get(`${API}/admin/requestAccess`, { headers: authHeader() });

const isAdmin = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user).admin;
};

export {
  stravaLogin,
  getCurrentUser,
  isLoggedIn,
  logout,
  requestAdminAccess,
  isAdmin,
};
