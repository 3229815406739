import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Row, Spinner, Container,
} from 'react-bootstrap';
import { requestAdminAccess } from '../services/auth';
import { resetStravaActivities } from '../services/admin';
import AdminResMessage from './AdminResMessage';
import { useViewport } from '../helpers/custom-hooks';

const AdminActivities = ({ admin, year }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState({ hidden: true, status: 'error', message: '' });
  const breakpoint = 890;
  const { width } = useViewport();

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') setIsAdmin(admin);
    requestAdminAccess().then(
      (res) => {
        if (res.status === 200) setIsAdmin(true);
      },
      (error) => {},
    );
  }, [admin]);

  const handleResetStrava = () => {
    setSpinner(true);
    resetStravaActivities(year).then((res) => {
      setSpinner(false);
      if (res.status === 200) {
        setMessage({
          hidden: false,
          status: 'success',
          message: 'Strava aktivity úspešne resetované',
        });
      }
    }).catch(() => {
      setSpinner(false);
      setMessage({
        hidden: false,
        status: 'error',
        message: 'Akcia sa nepodarila',
      });
    });
  };

  return (
    <Container className="mt-3">
      <div>
        {isAdmin ? (
          <div>
            {(width < breakpoint) ? (
              <Card style={{ textAlign: 'left' }}>
                <Card.Header>
                  <h2>
                    Reset Strava aktivít
                  </h2>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Týmto sa z databázy vymažú všetky Strava aktivity pre aktuálny rok súťaže.
                    Následne sa pre všetkých užívateľov znovu nahrajú.
                  </Card.Text>
                  <Row className="align-middle">
                    <Col>
                      <Button variant="success" className="me-2" onClick={handleResetStrava}>Reset Strava aktivít</Button>
                    </Col>
                    <Col>
                      <Spinner animation="border" variant="success" hidden={!spinner} />
                      <AdminResMessage hidden={message.hidden} status={message.status}>
                        {message.message}
                      </AdminResMessage>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <div>
                <Card style={{ width: '60%', textAlign: 'left', marginLeft: '20%' }}>
                  <Card.Header>
                    <h2>
                      Reset Strava aktivít
                    </h2>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      Týmto sa z databázy vymažú všetky Strava aktivity pre aktuálny rok súťaže.
                      Následne sa pre všetkých užívateľov znovu nahrajú.
                    </Card.Text>
                    <Row className="align-middle">
                      <Col>
                        <Button variant="success" className="me-2" onClick={handleResetStrava}>Reset Strava aktivít</Button>
                      </Col>
                      <Col>
                        <Spinner animation="border" variant="success" hidden={!spinner} />
                        <AdminResMessage hidden={message.hidden} status={message.status}>
                          {message.message}
                        </AdminResMessage>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        ) : (
          <h2>Na toto nemáte oprávnenie</h2>
        )}
      </div>
    </Container>
  );
};

AdminActivities.propTypes = {
  admin: PropTypes.bool,
  year: PropTypes.number.isRequired,
};

AdminActivities.defaultProps = {
  admin: false,
};

export default AdminActivities;
