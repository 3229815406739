import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const AdminResMessage = ({ hidden, status, children }) => {
  const [messageStatus, setMessageStatus] = useState(null);

  useEffect(() => {
    if (status === 'success') {
      setMessageStatus('green');
    } else {
      setMessageStatus('red');
    }
  }, [status]);

  return (
    <Card.Text hidden={hidden} style={{ color: messageStatus }}>{children}</Card.Text>
  );
};

AdminResMessage.propTypes = {
  hidden: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AdminResMessage;
