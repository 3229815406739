/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountain } from '@fortawesome/free-solid-svg-icons';
import Award from './Award';
import { useSortableData } from '../helpers/table-functions';

const AllUsersStatsTableCell = ({ data, field, showAwards }) => {
  const [tableData, setTableData] = useState('');
  const { items, requestSort } = useSortableData(tableData);

  useEffect(() => {
    setTableData(data);
    requestSort(field, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderAward = (index) => {
    if (!showAwards) return null;
    if (!['distance', 'elevation', 'longActivitiesCnt', 'longest'].includes(field)) return null;

    for (const pos of [1, 2, 3]) {
      if (items[index][field] === items[pos - 1][field]) return <Award pos={pos} />;
    }
    return null;
  };

  const renderRowValue = (row) => {
    switch (field) {
      case 'distance': return (
        <>
          {row[field].toFixed(1)}
          {' '}
          km
        </>
      );
      case 'elevation': return (
        <>
          {Math.round(row.elevation)}
          {' '}
          m
          {row.gerlaching
          && <FontAwesomeIcon icon={faMountain} className="achievement-icon" />}
        </>
      );
      case 'longest': return (
        <>
          {row.longest.toFixed(2)}
          {' '}
          km
        </>
      );
      default: return row[field];
    }
  };

  const markHighest = (index) => (field !== 'activitiesCnt' && items[index][field] === items[0][field] ? 'highest' : '');

  return (
    <Table responsive className="fixed" style={{ marginBottom: '0px', borderBottomStyle: 'hidden' }}>
      <tbody>
        {items && items.map((row, index) => (
          <tr key={row.id} className={row.eBike ? 'table-success' : ''}>
            <td>{row.name}</td>
            <td>
              <div className={`${markHighest(index)} mobileCellContainer`}>
                {renderAward(index)}
                {renderRowValue(row)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

AllUsersStatsTableCell.propTypes = {
  data: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  showAwards: PropTypes.bool,
};

AllUsersStatsTableCell.defaultProps = {
  showAwards: false,
};

export default AllUsersStatsTableCell;
