import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { requestAdminAccess } from '../services/auth';

const AdminUsers = ({ admin }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') setIsAdmin(admin);
    requestAdminAccess().then(
      (res) => {
        if (res.status === 200) setIsAdmin(true);
      },
      (error) => {},
    );
  }, [admin]);

  return (
    <Container className="mt-3">
      {isAdmin ? (
        <h2>Admin Portál - Užívatelia</h2>
      ) : (
        <h2>Na toto nemáte oprávnenie</h2>
      )}
    </Container>
  );
};

AdminUsers.propTypes = {
  admin: PropTypes.bool,
};

AdminUsers.defaultProps = {
  admin: false,
};

export default AdminUsers;
