import React from 'react';
import { Container } from 'react-bootstrap';
import { getCurrentUser, isLoggedIn } from '../services/auth';

const Profile = () => {
  if (!isLoggedIn) {
    // eslint-disable-next-line no-undef
    window.open('/home');
  }
  const user = getCurrentUser();
  return (
    <Container className="mt-3">
      <h2>Profil</h2>
      <p>
        <b>Meno: </b>
        {user.username}
      </p>
      <p>
        <b>Strava ID: </b>
        {user.stravaId}
      </p>
    </Container>
  );
};

export default Profile;
