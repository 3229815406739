import { useState, useMemo } from 'react';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedtableData = useMemo(() => {
    const sortabletableData = [...items];
    if (sortConfig !== null) {
      sortabletableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortabletableData;
  }, [items, sortConfig]);

  const requestSort = (key, mobile) => {
    let direction = 'descending';
    if (
      sortConfig
          && sortConfig.key === key
          && sortConfig.direction === 'descending'
    ) {
      direction = 'ascending';
    }
    if (mobile) direction = 'descending';
    setSortConfig({ key, direction });
  };

  return { items: sortedtableData, requestSort, sortConfig };
};

const isHighest = (row, field, sortedData) => row[field] === sortedData[field][0];

const sortField = (data, field) => data
  .map((x) => x[field])
  .sort((a, b) => parseFloat(a, 10) - parseFloat(b, 10))
  .reverse();

// Transforms array of objects [{ field => value }]
// into an object of arrays { field => [sorted values]}
const sortData = (data) => Object.fromEntries([
  'distance',
  'elevation',
  'longActivitiesCnt',
  'longest'].map((field) => [field, sortField(data, field)]));

export {
  useSortableData,
  isHighest,
  sortData,
};
