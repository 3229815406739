import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountain } from '@fortawesome/free-solid-svg-icons';

import './AllUsersStatsTable.css';
import Award from './Award';
import { useSortableData, isHighest, sortData } from '../helpers/table-functions';

const AllUsersStatsTable = ({ data, showAwards }) => {
  const [tableData, setTableData] = useState('');
  const [sortedData, setSortedData] = useState(null);
  const { items, requestSort, sortConfig } = useSortableData(tableData);

  useEffect(() => {
    setTableData(data);
    requestSort('distance');
    setSortedData(sortData(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return 0;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const markHighest = (row, field) => (isHighest(row, field, sortedData) ? 'highest' : '');

  const renderAward = (row, field) => {
    if (!showAwards) return null;
    for (const pos of [1, 2, 3]) {
      if (row[field] === sortedData[field][pos - 1]) return <Award pos={pos} />;
    }
    return null;
  };

  return (
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th>
            <button type="button" style={{ whiteSpace: 'nowrap' }} onClick={() => requestSort('name')} className={getClassNamesFor('name')}>
              Atlét
            </button>
          </th>
          <th>
            <button type="button" style={{ whiteSpace: 'nowrap' }} onClick={() => requestSort('distance')} className={getClassNamesFor('distance')}>
              Vzdialenosť
            </button>
          </th>
          <th>
            <button type="button" style={{ whiteSpace: 'nowrap' }} onClick={() => requestSort('elevation')} className={getClassNamesFor('elevation')}>
              Prevýšenie
            </button>
          </th>
          <th>
            <button type="button" onClick={() => requestSort('activitiesCnt')} className={getClassNamesFor('activitiesCnt')}>
              Počet jázd
            </button>
          </th>
          <th>
            <button type="button" onClick={() => requestSort('longActivitiesCnt')} className={getClassNamesFor('longActivitiesCnt')}>
              Počet jázd
              {' '}
              {'>'}
              {' '}
              30 km
            </button>
          </th>
          <th>
            <button type="button" onClick={() => requestSort('longest')} className={getClassNamesFor('longest')}>
              Najdlhšia aktivita
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {items && items.map((row) => (
          <tr key={row.id} className={row.eBike ? 'table-success' : ''}>
            <td>{row.name}</td>
            <td>
              <div className="cellContainer distance">
                <div>{renderAward(row, 'distance')}</div>
                <div className={markHighest(row, 'distance')}>
                  {row.distance.toFixed(1)}
                  {' '}
                  km
                </div>
              </div>
            </td>
            <td>
              <div className="cellContainer elevation">
                <div>{renderAward(row, 'elevation')}</div>
                <div className={markHighest(row, 'elevation')}>
                  {Math.round(row.elevation)}
                  {' '}
                  m
                </div>
                <div>
                  {row.gerlaching
                    && (
                      <FontAwesomeIcon
                        icon={faMountain}
                        className="achievement-icon"
                        title="Užívateľ dokončil výzvu Gerlaching"
                      />
                    )}
                </div>
              </div>
            </td>
            <td>{row.activitiesCnt}</td>
            <td>
              <div className="cellContainer longActivitiesCnt">
                <div>{renderAward(row, 'longActivitiesCnt')}</div>
                <div className={markHighest(row, 'longActivitiesCnt')}>
                  {row.longActivitiesCnt}
                </div>
              </div>
            </td>
            <td>
              <div className="cellContainer longest">
                <div>{renderAward(row, 'longest')}</div>
                <div className={markHighest(row, 'longest')}>
                  {row.longest.toFixed(2)}
                  {' '}
                  km
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

AllUsersStatsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  showAwards: PropTypes.bool,
};

AllUsersStatsTable.defaultProps = {
  showAwards: false,
};

export default AllUsersStatsTable;
